
import { computed, defineComponent, onMounted, ref } from 'vue';
import swal from 'sweetalert2';
import { generalStore } from '@/store';
import { useRoute } from 'vue-router';
import { Employee, EmployeeSchedule } from '@/models/Employee';
import { api } from '@/services/Api';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { DayOfWeek } from '@/models/Enums';

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props: any) {
        const { t } = useI18n();
        const route = useRoute();
        const employeeId = ref(props.id);
        const employee = ref<Employee>(JSON.parse(JSON.stringify(generalStore.state.employee)));
        const weekDayNames = DayOfWeek;

        async function getEmployee(id: string): Promise<Employee | null> {
            if (!id) return null;
            if (!employee.value || employee.value.id.toString() != id) {
                swal.showLoading();
                const response = await api.getEmployee(id);
                if (response.data) {
                    employee.value = response.data;
                    const employeeSchedules = [];
                    for (const item in weekDayNames) {
                        if (!isNaN(Number(item))) {
                            const scheduleIndex = employee.value.employeeSchedules.findIndex(x => x.dayOfWeek == Number(item));
                            if (scheduleIndex >= 0) {
                                const existItem = employee.value.employeeSchedules[scheduleIndex];
                                existItem.active = true;
                                employeeSchedules.push(existItem);
                            } else {
                                const newItem = new EmployeeSchedule();
                                newItem.dayOfWeek = Number(item);
                                employeeSchedules.push(newItem);
                            }
                        }
                    }
                    employee.value.employeeSchedules = employeeSchedules;
                    swal.close();
                } else {
                    const result = await swal.fire({
                        icon: 'error',
                        title: 'Such employee does not exist.',
                        text: response.errorMessage,
                        confirmButtonText: t('alert.backToCatalog')
                    });
                    if (result.isConfirmed) {
                        router.push({ name: 'EmployeesTable' });
                    }
                }
            }

            return generalStore.state.employee;
        }

        const isAllDay = (start: string, end: string) => {
            const [startHours, startMinutes] = start.split(':').map(Number);
            const [endHours, endMinutes] = end.split(':').map(Number);

            if (startHours + 23 === endHours && startMinutes + 59 === endMinutes) {
                return true;
            }

            return false;
        };

        async function onMountedAction() {
            swal.showLoading();
            const newId = route.params.id.toString();
            await getEmployee(newId);

            if (!employee.value.id) {
                employeeId.value = newId;
            }
            swal.close();
        }

        const getCurrencySymbol = (id: string) => generalStore.getters.getCurrencySymbol(id);

        const edit = () => {
            router.push({ name: 'EditEmployee', params: { id: employeeId.value } });
        };
        function toEmployeesTable() {
            router.push({ name: 'EmployeesTable', params: { newSearch: '1' } });
        }

        onMounted(onMountedAction);
        return {
            edit,
            toEmployeesTable,
            employee,
            getCurrencySymbol,
            weekDayNames,
            isAllDay
        };
    }
});
